//
//
//
//
//
//
//
//
//
//


export default {
  props: { image: Object, CssClass: String}
};
